import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import { GuideStepGroupId } from 'shared-entities'

const steps: StepViewItem[] = [
  {
    id: GuideStepGroupId.Landing,
    title: 'Guide',
    subItems: [
      {
        id: 100,
        title: 'Before landing',
        component: require('./steps/PrGuideBeforeLanding.vue').default,
      },
      {
        id: 105,
        title: 'Crossing the border: Documents',
        component: require('./steps/PrGuideDocuments.vue').default,
      },
      {
        id: 110,
        title: 'Interview process',
        component: require('./steps/PrGuideInterview.vue').default,
      },
      {
        id: 115,
        title: 'Bringing goods to Canada',
        component: require('./steps/PrGuideBringGoods.vue').default,
      },
      {
        id: 120,
        title: 'Soft landing / PRTD',
        component: require('./steps/PrGuidePRTD.vue').default,
      },
      {
        id: 125,
        title: 'PR status and PR card',
        component: require('./steps/PrGuidePRcard.vue').default,
      },
    ],
  },
]

export default steps
