import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import { GuideStepGroupId } from 'shared-entities'

const steps: StepViewItem[] = [
  {
    id: GuideStepGroupId.Visa,
    title: 'Guide',
    subItems: [
      {
        id: 100,
        title: 'Change of circumstances',
        component: require('./steps/PrGuideChangeCircumstances.vue').default,
      },
      {
        id: 105,
        title: 'Determine if you need to submit your passport(s)',
        component: require('./steps/PrGuidePassportRequirements.vue').default,
      },
      {
        id: 110,
        title: 'Visa validity',
        component: require('./steps/PrGuideVisaValidity.vue').default,
      },
      {
        id: 115,
        title: 'Prepare your documents',
        component: require('./steps/PrGuidePrepareDocuments.vue').default,
      },
      {
        id: 120,
        title: 'Submit your documents',
        component: require('./steps/PrGuideSubmitDocuments.vue').default,
      },
    ],
  },
]

export default steps
